<template>
  <v-row class="col-sm-12">
    <FacilitiesWarning
      :disableAlert="disableAlert"
    />
    <!-- Header -->
    <v-col
      cols="12"
    >
      <app-card-actions
        action-refresh

        @refresh="refreshFacilities"
      >
        <template
          slot="title"
        >
          Facility Management
        </template>
      </app-card-actions>
    </v-col>

    <!-- Available Facilities table -->
    <v-col
      cols="5"
    >
      <v-tabs
        fixed-tabs
        class="borderless-tabs"
      >
        <v-tab
          class="facility-fixed-tab-width"
          href="#add_remove_facility"
        >
          Available Facilities ({{ displayAvailFacilities() }})
        </v-tab>
        <v-tab-item
          class="borderless-tabs"
          value="add_remove_facility"
        >
          <app-card-actions
            no-actions
          >
            <template
              slot="title"
            >
              <v-col cols="12">
                <v-text-field
                  v-model="searchInactive"
                  label="Search"
                  single-line
                  hide-detail
                  clearable
                >
                </v-text-field>
              </v-col>
            </template>

            <v-data-table
              ref="searchAvailFacilities"
              v-model="selectedInactive"
              show-select
              show-select-all
              :items="availFacilities"
              item-key="id"
              :headers="headers"
              :loading="loading"
              :page.sync="pagination.page"
              :items-per-page="pagination.itemsPerPage"
              :search="searchInactive"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              hide-default-footer
              class="has-pagination facility-list"
              loading-text="Loading... Please wait..."
              @page-count="pagination.pageCount = $event"
            >
              <template #header.data-table-select>
                <!-- Empty header to remove the select-all checkbox -->
              </template>
              <template #item.name="{ item }">
                <span :class="item.name">
                  {{ item.name }}
                </span>
              </template>
            </v-data-table>

            <pagination
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.itemsPerPage"
              :page-count="pagination.pageCount"
            ></pagination>
          </app-card-actions>
        </v-tab-item>
        <v-tab
          class="facility-fixed-tab-width"
          href="#recently_synced_facilities"
        >
          Recently Synced Facilities
        </v-tab>
        <v-tab-item
          class="borderless-tabs"
          value="recently_synced_facilities"
        >
          <v-col cols="12">
            <app-card-actions
              no-actions
            >
              <template
                slot="title"
              >
                <v-col cols="12">
                  <v-text-field
                    v-model="searchRecentlySynced"
                    label="Search"
                    single-line
                    hide-detail
                    clearable
                    style="margin-top:2px;padding-top:2px;margin-bottom:2px;padding-bottom:2px;"
                  >
                  </v-text-field>
                </v-col>
              </template>

              <v-col cols="12">
                <v-data-table
                  :headers="recentlySyncedHeader"
                  item-key="id"
                  :items="myRecentlySyncedFacilities"
                  :items-per-page="recentlySyncedPaginator.itemsPerPage"
                  :loading="loading"
                  :page.sync="recentlySyncedPaginator.page"
                  :search="searchRecentlySynced"
                  hide-default-footer
                  class="has-pagination facility-list recently-synced-facilities"
                  loading-text="Loading... Please wait..."
                  @page-count="recentlySyncedPaginator.pageCount = $event"
                >
                  <template v-slot:header.id="{ header }">
                    <v-simple-checkbox
                      :ripple="false"
                      :value="isAllSelected"
                      @click="toggleAllSelection"
                    ></v-simple-checkbox>
                  </template>
                  <template #item.id="{ item }">
                    <v-simple-checkbox
                      :ripple="false"
                      :disabled="isItemDisabled(item)"
                      :value="selectedInactive.some(facility => facility.id === item.id)"
                      @click.stop="toggleSelection(item)"
                    ></v-simple-checkbox>
                  </template>
                  <template
                    #item.title="{ item }"
                  >
                    <span :class="[item.title, { 'disabled-row': isItemDisabled(item) }]">
                      {{ item.title }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
              <pagination
                :page.sync="recentlySyncedPaginator.page"
                :items-per-page.sync="recentlySyncedPaginator.itemsPerPage"
                :page-count="recentlySyncedPaginator.pageCount"
              ></pagination>
            </app-card-actions>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-col>

    <!-- Selector / Transfer buttons -->
    <v-col
      flex
      justify-space-between
      align-center
      class="px-0 my-16"
      cols="2"
    >
      <v-card-text>
        <btn
          :icon="icons.mdiArrowRightBoldBox"
          class="buttons"
          color="success"
          :disabled="disableAdd()"
          @click="addActive()"
        >
          <div class="removeText">
            Add
          </div>
        </btn>
      </v-card-text>

      <v-card-text>
        <btn
          :icon="icons.mdiArrowLeftBoldBox"
          color="error"
          class="buttons"
          :disabled="disableRemove()"
          @click="removeActive()"
        >
          <div class="removeText">
            Remove
          </div>
        </btn>
      </v-card-text>
    </v-col>

    <!-- Current Facilities -->
    <v-col
      cols="5"
    >
      <app-card-actions
        no-actions
      >
        <template
          slot="title"
          width="100%"
        >
          <v-col cols="12">
            Your Facilities ({{ displayGoFacilities() }})
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="searchActive"
              label="Search"
              single-line
              hide-detail
              clearable
            >
            </v-text-field>
          </v-col>
        </template>

        <v-data-table
          ref="searchGoFacilities"
          v-model="selectedActive"
          show-select
          :headers="headers"
          item-key="id"
          :items="activeFacilities"
          :items-per-page="pagination2.itemsPerPage"
          :loading="loading"
          :page.sync="pagination2.page"
          :search="searchActive"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          hide-default-footer
          class="has-pagination facility-list"
          loading-text="Loading... Please wait..."
          @page-count="pagination2.pageCount = $event"
        >
          <template
            #item.title="{ item }"
          >
            <span :class="item.title">
              {{ item.title }}
            </span>
          </template>
        </v-data-table>

        <pagination
          :page.sync="pagination2.page"
          :items-per-page.sync="pagination2.itemsPerPage"
          :page-count="pagination2.pageCount"
        ></pagination>
      </app-card-actions>
    </v-col>

    <!-- Reset and Save buttons -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col
        cols="3"
      >
        <v-card-text>
          <btn
            :icon="icons.mdiSync"
            class="buttons"
            @click="resetChanges()"
          >
            Reset
          </btn>
        </v-card-text>
      </v-col>

      <v-col
        cols="3"
      >
        <v-card-text>
          <btn
            :icon="icons.mdiCloudCheckVariant"
            :disabled="disableSave()"
            color="success"
            class="buttons"
            @click="modalState = true"
          >
            Save
          </btn>
        </v-card-text>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <!-- Debugging -->
    <v-col
      v-if="DEBUG"
      cols="12"
    >
      <app-card-actions
        no-actions
      >
        <template
          slot="title"
        >
          <ul list-style="none">
            Initial:
            <li
              v-for="title in initialFacilities"
              :key="title.id"
            >
              {{ title.title }}
            </li>
          </ul>

          <ul>
            toRemove:
            <li
              v-for="title in removeFacilities"
              :key="title.id"
            >
              {{ title.title }}
            </li>
          </ul>

          <ul>
            toAdd:
            <li
              v-for="title in addFacilities"
              :key="title.id"
            >
              {{ title.title }}
            </li>
          </ul>
        </template>
      </app-card-actions>
    </v-col>

    <v-dialog
      v-model="modalState"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title
          class="pb-0"
        >
          Update your facility list?
        </v-card-title>
        <v-row
          class="mx-0 mb-5"
        >
          <v-col
            cols="6"
          >
            <NoteSection
              label="Add"
            >
              <div
                v-if="addFacilities.length <= 0"
                class="pb-4"
              >
                No facilities to add!
              </div>
              <ul
                v-if="addFacilities.length > 0"
              >
                <li
                  v-for="title in addFacilities"
                  :key="title.id"
                  class="mx-5"
                >
                  {{ title.title }}
                </li>
              </ul>
            </NoteSection>
          </v-col>

          <v-col
            cols="6"
          >
            <NoteSection
              label="Remove"
            >
              <div
                v-if="removeFacilities.length <= 0"
                class="pb-4"
              >
                No facilities to remove!
              </div>
              <ul
                v-if="removeFacilities.length > 0"
              >
                <li
                  v-for="title in removeFacilities"
                  :key="title.id"
                  class="mx-5"
                >
                  {{ title.title }}
                </li>
              </ul>
            </NoteSection>
          </v-col>
        </v-row>

        <v-card-actions>
          <btn
            label="Close"
            color="secondary"
            :icon="icons.mdiClose"
            @click="modalState = false"
          ></btn>

          <v-spacer></v-spacer>

          <btn
            label="Save And Sync"
            color="success"
            :icon="icons.mdiCloudCheckVariant"
            :disabled="disableSave()"
            @click="syncFacilities()"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.facility-fixed-tab-width {
  width: 120px !important;
  padding: 3px;
}
</style>

<script>
import FacilitiesWarning from '@/components/elements/facility/FacilitiesWarning.vue'
import startSync from '@/mixins/SyncEncounters'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'

import {
  mdiArrowLeftBoldBox,
  mdiArrowRightBoldBox,
  mdiClose,
  mdiCloseCircle,
  mdiCloudCheckVariant,
  mdiSync,
} from '@mdi/js'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const initialState = {
  by: 'title',
  desc: false,
}

export default {
  components: { AppCardActions, FacilitiesWarning },
  mixins: [startSync],
  data() {
    const refreshFacilities = hideOverlay => {
      hideOverlay()
      if (this.online) {
        this.$root.confirm({
          title: 'Refresh facility list?',
          confirm: 'Refresh',
          confirmIcon: this.icons.mdiSync,
          confirmColor: 'success',
        }).then(result => {
          if (this.online && result === true) {
            this.$store.dispatch('encounters/syncingModal', true)
            Vue.auth.fetch()
              .then(() => this.$store.dispatch('encounters/syncingModal', true))
              .then(() => this.$store.dispatch('facilities/loadFacilities'))
              .then(() => this.resetAll())
              .then(() => this.$store.dispatch('encounters/syncingModal', false))
              .then(() => this.$store.dispatch('notify', { value: 'Available facility list successfully refreshed', color: 'success' }))
          }
        })
      } else {
        this.$store.dispatch('notify', { value: 'Cannot refresh facilities while offline', color: 'error' })
      }
    }

    return {
      // DEBUG
      DEBUG: false,

      // SYNC DEFAULTS
      syncPos: false,
      syncCnt: false,

      refreshFacilities,

      // v-dialog visibility
      modalState: false,

      disableAlert: false,

      userID: '',

      // Reset to initial go list
      initialFacilities: [],

      // Current go list and facility list to choose from
      activeFacilities: [],
      availFacilities: [],

      // Post/Delete request data
      removeFacilities: [],
      addFacilities: [],

      // v-model, checkmark for NOT go list
      selectedActive: [],

      // v-model, checkmark for go list
      selectedInactive: [],

      // Search functionality in tables
      searchActive: '',
      searchInactive: '',
      searchRecentlySynced: '',

      sort: initialState,

      loading: false,
      pagination2: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      recentlySyncedPaginator: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
      },
      icons: {
        mdiArrowRightBoldBox,
        mdiArrowLeftBoldBox,
        mdiClose,
        mdiCloudCheckVariant,
        mdiCloseCircle,
        mdiSync,
      },
      headers: [
        {
          text: 'Name', value: 'title', sortable: false,
        },
      ],
      recentlySyncedHeader: [
        {
          text: '', value: 'id', sortable: false,
        },
        {
          text: 'Name', value: 'title', sortable: false,
        },
      ],
      isVisible: true,
    }
  },
  computed: {
    ...mapFields(['online']),
    ...mapGetters('auth', { myFacilities: 'facilities', myRecentlySyncedFacilities: 'recentlySyncFacilities' }),
    ...mapGetters('facilities', {facilities: 'facilities', maxFacilities: 'maxFacilities'}),
    ...mapGetters('encounters', ['syncing']),
    ...mapActions('auth', ['sendPlacesOfService']),
    ...mapActions('patients', ['loadPatients']),
    isAllSelected() {
      // Early exit if there are no displayed items.
      if (!this.currentDisplayedItems.length) return false

      // Get only non disabled items
      const nonDisabledDisplayedItems = this.currentDisplayedItems.filter(item => !this.isItemDisabled(item))

      // Early exit if there are no displayed items.
      if (nonDisabledDisplayedItems.length === 0) return false

      // Check if every non-disabled displayed item is in the selectedInactive.
      return nonDisabledDisplayedItems.every(item => this.selectedInactive.some(facility => facility.id === item.id))
    },
    currentDisplayedItems() {
      const start = (this.recentlySyncedPaginator.page - 1) * this.recentlySyncedPaginator.itemsPerPage
      const end = start + this.recentlySyncedPaginator.itemsPerPage

      return this.myRecentlySyncedFacilities.slice(start, end)
    },

    // These appear to be unnecessary for this component, so I'm disabling this.
    // ...mapState('encounters', { encounters: 'items' }),
    // ...mapState('patients', { patients: 'items' }),
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedInactive(newVal, oldVal) {
      this.alertHandler()
      this.changesMade()
    },

    // eslint-disable-next-line no-unused-vars
    selectedActive(newVal, oldVal) {
      this.changesMade()
    },

    // Watch for any changes
    // eslint-disable-next-line no-unused-vars
    disableSave(newVal, oldVal) {
      this.changesMade()
    },
  },
  created() {
    this.userID = this.$store.getters['auth/user'].id
    this.resetAll()

    // Skip initial sync for debugging
    if (!this.DEBUG && this.checkRecordsToPost()) {
      this.willSync()
    }
  },
  methods: {
    toggleSelection(item) {
      if (this.isItemDisabled(item)) return

      const index = this.selectedInactive.findIndex(facility => facility.id === item.id)
      if (index > -1) {
        this.selectedInactive.splice(index, 1)
      } else {
        this.selectedInactive.push(item)
      }
    },
    toggleAllSelection() {
      console.log('isAllSelected', this.isAllSelected)
      if (this.isAllSelected) {
        // Clear selection if all are selected
        this.selectedInactive = []
      } else {
        // Select only those that are currently displayed and not disabled
        this.selectedInactive = [
          ...this.selectedInactive,
          ...this.currentDisplayedItems.filter(item => !this.isItemDisabled(item)
            && !this.selectedInactive.some(facility => facility.id === item.id)),
        ]
      }
    },
    isItemDisabled(item) {
      // Here, check if the item exists in activeFacilities.
      const isItemDisabled = this.activeFacilities.some(activeItem => activeItem.id === item.id)

      return isItemDisabled
    },
    alertHandler() {
      if (this.maxFacilities >= (this.initialFacilities.length - this.removeFacilities.length) + this.addFacilities.length + this.selectedInactive.length) {
        this.disableAlert = false
      } else {
        this.disableAlert = true
      }
    },
    willSync() {
      this.$root.confirm({
        title: 'A stable internet connection is required',
        subTitle: 'Warning: Editing your facilities will require a FULL SYNC',
        confirm: 'Continue',
        confirmIcon: this.icons.mdiSync,
        confirmColor: 'error',
      }).then(result => {
        if (result === true) {
          this.syncAllEncounters()
        } else {
          this.$router.go(-1)
        }
      })
    },
    isDevMode() {
      return process.env.NODE_ENV === 'development'
    },

    /// Disable button handlers
    disableAdd() {
      // Disables button when facility is selected from available list
      if (this.selectedInactive.length === 0) {
        return true
      }

      return this.activeFacilities.length + this.selectedInactive.length > this.maxFacilities
    },
    disableRemove() {
      // Disables button when facility is selected from go list
      if (this.selectedActive.length === 0) {
        return true
      }

      return false
    },
    disableSave() {
      if (this.addFacilities.length === 0 && this.removeFacilities.length === 0) {
        return true
      }

      return this.activeFacilities.length > this.maxFacilities
    },

    removeActive() {
      // Concats available facility list to include selected facilities from go list table
      this.availFacilities = [...this.selectedActive, ...this.availFacilities]
      this.activeFacilities = this.activeFacilities.filter(facility => !this.availFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))

      // Adds to removeFacilities if selected go list facilities did not exist in initial go list and if the facility was not already in removeFacilities
      this.removeFacilities = [...this.removeFacilities, ...this.initialFacilities.filter(facility => this.selectedActive.map(facilityIDs => facilityIDs.id).includes(facility.id)
        && !this.removeFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))]

      // Updates addFacilities
      this.addFacilities = this.addFacilities.filter(facility => !this.selectedActive.map(facilityIDs => facilityIDs.id).includes(facility.id))

      // Resets selected facilities from go list table
      this.resetSelectedActive()

      // update alert state
      this.alertHandler()
    },
    addActive() {
      // Concats selected facilities from available facilities table, to go list table

      this.activeFacilities = [...this.selectedInactive, ...this.activeFacilities]

      // Filters available facilities table by new go list
      this.availFacilities = this.availFacilities.filter(facility => !this.activeFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))

      // Adds to addFacilities if selected available facilities did not exist in initial go list and if the facility was not already in addFacilities
      this.addFacilities = [...this.addFacilities, ...this.selectedInactive.filter(facility => !this.initialFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id)
      && !this.addFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))]

      // Updates removeFacilities
      this.removeFacilities = this.removeFacilities.filter(facility => !this.selectedInactive.map(facilityIDs => facilityIDs.id).includes(facility.id))

      // Resets selected facilities from available facilities table
      this.resetSelectedInactive()
    },

    // Sync facilities
    syncFacilities() {
      this.$root.confirm({
        title: 'Are you sure you want to sync?',
        confirm: 'yes, sync',
        confirmIcon: this.icons.mdiSync,
        confirmColor: 'success',
      }).then(result => {
        if (result === true) {
          console.log('starting facility sync')
          this.$store.dispatch('encounters/syncingProgress', { pos: 0, cnt: 5 })
          this.$store.dispatch('encounters/syncingModal', true)
          Promise.all([
            this.syncRemoveFacilities(),
            this.syncAddFacilities(),
          ]).then(test => {
            console.log(`Remove Facilities: ${test[0]} Add Facilities: ${test[1]}`)
            if (test[0] && test[1]) {
              console.log('syncAll')
              this.startSync().then(async () => {
                this.resetAll()
                this.modalState = false
                this.$store.dispatch('encounters/syncingModal', false)

                // this.$store.dispatch('notify', { value: 'Sync all data successful.', color: 'success' })
              }).catch(error => {
                console.error('Error during syncAll:', error)
                this.$store.dispatch('encounters/syncingModal', false)
                this.$store.dispatch('notify', { value: `Error syncing all data: ${error}`, color: 'error' })
              })
            }
          }).catch(e => {
            this.$store.dispatch('encounters/syncingModal', false)
            this.$store.dispatch('notify', { value: `Error syncing Go List ${e}`, color: 'error' })
            console.log(e)
          })
        }
      })
    },

    // Progress of current sync
    syncingProgress() {
      this.$store.dispatch('encounters/syncingProgress', { pos: this.syncPos, cnt: this.syncCnt })
      this.syncPos += 1
    },

    getCnt() {
      // '3' for load encounters/patients and Vue.fetch()
      let tempCnt = 3

      if (this.removeFacilities.length !== 0) {
        tempCnt += 1
      }
      if (this.addFacilities.length !== 0) {
        tempCnt += 1
      }

      return tempCnt
    },

    ///
    // Errors

    // Too many facilities in go list
    maxFacilitiesError() {
      return (this.selectedInactive.length + this.activeFacilities.length) - (this.maxFacilities)
    },

    ///
    // Reset Functions

    // Reset any changes made
    resetChanges() {
      this.activeFacilities = this.initialFacilities
      this.availFacilities = this.facilities.filter(facility => !this.myFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))
      this.resetSelectedActive()
      this.resetSelectedInactive()
      this.resetAddFacilities()
      this.resetRemoveFacilities()
    },

    // Reset table selects
    resetSelectedActive() {
      this.selectedActive = []
    },
    resetSelectedInactive() {
      this.selectedInactive = []
    },

    // Reset facilities
    resetAddFacilities() {
      this.addFacilities = []
    },
    resetRemoveFacilities() {
      this.removeFacilities = []
    },

    // Resets go list, available list, and initial facilities based on current user's places of service assignments
    resetFacilities() {
      this.activeFacilities = this.facilities.filter(facility => this.myFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))
      this.availFacilities = this.facilities.filter(facility => !this.myFacilities.map(facilityIDs => facilityIDs.id).includes(facility.id))
      this.initialFacilities = this.activeFacilities
    },

    // Resets
    resetAll() {
      this.resetFacilities()
      this.resetSelectedActive()
      this.resetSelectedInactive()
      this.resetAddFacilities()
      this.resetRemoveFacilities()
    },

    /// //////////////////////

    // Displays Facility Number to user
    displayAvailFacilities() {
      return (this.searchInactive !== '' ? this.$refs.searchAvailFacilities.$children[0].filteredItems.length : this.availFacilities.length + this.removeFacilities.length)
    },
    displayGoFacilities() {
      return (this.searchActive !== '' ? this.$refs.searchGoFacilities.$children[0].filteredItems.length : this.activeFacilities.length)
    },

    // Warning Message
    warningMessage() {
      return `You are attempting to add ${this.maxFacilitiesError()} more ${this.maxFacilitiesError() > 1 ? 'facilities' : 'facility'} which will exceed the maximum allowable of ${this.maxFacilities}.`
    },

    // Checks for changes made
    changesMade() {
      if (!this.disableSave() || this.selectedInactive.length !== 0 || this.selectedActive.length !== 0) {
        this.$emit('changes', true)
      } else {
        this.$emit('changes', false)
      }
    },

    // Checks for any records to post
    checkRecordsToPost() {
      if (this.patientsToSync.length > 0 || this.encountersToSync.length > 0) {
        return true
      }

      return false
    },

    async syncAddFacilities() {
      let errorCatch = ''
      if (this.addFacilities.length !== 0) {
        console.log('start add process')
        console.log(`Facilities to add: ${this.addFacilities.length}`)

        // setup facilities for sync log
        if (!this.addFacilities[0]?.sync_log) {
          this.addFacilities = JSON.parse(JSON.stringify(this.addFacilities))
          this.addFacilities[0].sync_log = this.syncLogSetup('Facility Sync: Add')
        }

        await this.$store.dispatch('auth/addFacilities', this.addFacilities)
          .then(success => {
            // TODO: store in vuex the recently synced facilities

            if (success !== true) {
              const error = `Go list post error: ${success && success.message ? `${success.message}` : 'Failed!'}`
              errorCatch += `${error}<br><br>`
              console.log(error)
            }
          })
      }

      if (this.addFacilities.length) console.log('Add process finished.')

      // this.syncingProgress()

      return errorCatch.length === 0 ? true : errorCatch
    },

    async syncRemoveFacilities() {
      let errorCatch = ''
      if (this.removeFacilities.length !== 0) {
        console.log('start removal process')
        console.log(`Facilities to remove: ${this.removeFacilities.length}`)

        // setup facilities for sync log
        if (!this.removeFacilities[0]?.sync_log) {
          this.removeFacilities = JSON.parse(JSON.stringify(this.removeFacilities))
          this.removeFacilities[0].sync_log = this.syncLogSetup('Facility Sync: Remove')
        }

        await this.$store.dispatch('auth/removeFacilities', this.removeFacilities)
          .then(success => {
            // Remove from recently synced facilities if facilities are in the list
            if (success !== true) {
              const error = `Go list delete error: ${success && success.message ? `${success.message}` : 'Failed!'}`
              errorCatch += `${error}<br><br>`
              console.log(error)
            }
          })
      }

      if (this.removeFacilities.length) console.log('Removal process finished.')

      // this.syncingProgress()

      return errorCatch.length === 0 ? true : errorCatch
    },
  },
  mutations: {
  },
  setters: {
  },
}
</script>

<style lang="scss">

.v-data-table.facility-list .v-data-table__wrapper table tbody {
  tr {
    background-color: rgba(0, 0, 0, 0.08);
    td {
      padding: 0 0 !important;
      > * {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0 6px;
        padding-top: 13px;
      }
      .new {
        font-weight: 600;
        background-color: map-get($shades, 'white');
      }
    }
    &.v-data-table__empty-wrapper {
      background-color: map-get($shades, 'white');
    }
  }
}

.v-chip.v-size--default {
    border-radius: 16px;
    height: 50px;
}

.v-pagination li button {
  min-height: 30px !important;
  min-width: 30px !important;
}

.buttons {
  width: 100%
}

@media only screen and (max-width:960px) {
  .removeText {
    display: none
  }
}
</style>
